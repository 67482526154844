import * as types from '../actions/actionTypes'; // eslint-disable-line

export default function CalendarReducer(state = { feeds: [] }, action) {
    switch (action.type) {
        case types.GET_CALENDARS_SUCCESS:
            return {
                ...state,
                feeds: action.payload,
                inProgress: false,
            };
        case types.GET_CALENDARS_PENDING:
            return {
                ...state,
                feeds: [],
                inProgress: true,
            };
        case types.GET_CALENDARS_FAILED:
            return {
                ...state,
                inProgress: false,
            };
        default:
            return state;
    }
}
