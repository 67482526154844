import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PROJECT_VERSION_MODULES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                allInProgress: false,
            };
        case types.GET_PROJECT_VERSION_MODULES_PENDING:
            return {
                ...state,
                allInProgress: true,
            };
        case types.GET_PROJECT_VERSION_MODULES_FAILED:
            return {
                ...state,
                error: action.error,
                data: [],
                allInProgress: false,
            };
        case types.GET_MODULE_SUCCESS:
            return {
                ...state,
                select: action.payload,
                inProgress: false,
            };
        case types.GET_MODULE_PENDING:
            return {
                ...state,
                inProgress: true,
            };
        case types.GET_MODULE_FAILED:
            return {
                ...state,
                error: action.error,
                inProgress: false,
            };
        case types.UPDATE_MODULE_SUCCESS:
        case types.UPDATE_MODULE_PARAMETER_SUCCESS:
            return {
                ...state,
                select: action.payload,
                inProgress: false,
                justSaved: true,
                error: '',
            };
        case types.UPDATE_MODULE_PENDING:
        case types.UPDATE_MODULE_PARAMETER_PENDING:
            return {
                ...state,
                inProgress: true,
                justSaved: false,
                error: '',
            };
        case types.CALENDAR_FEED_INVALID:
            return {
                ...state,
                icalFeedUrl: '',
            };
        case types.UPDATE_MODULE_FAILED:
        case types.UPDATE_MODULE_PARAMETER_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
        case types.DISCONNECT_MEDIA_SUCCESS:
            return {
                ...state,
                select: {
                    ...state.select,
                    featuredId: null,
                    showFeatured: false,
                },
                inProgress: false,
            };
        case types.DISCONNECT_MEDIA_PENDING:
            return {
                ...state,
                inProgress: true,
            };
        case types.DISCONNECT_MEDIA_FAILED:
            return {
                ...state,
                inProgress: false,
            };
        case types.UPDATE_MODULES_SUCCESS:
            return {
                ...state,
                select: action.payload,
                inProgress: false,
                justSaved: true,
                error: '',
            };
        case types.UPDATE_MODULES_PENDING:
            return {
                ...state,
                inProgress: true,
                justSaved: false,
                error: '',
            };
        case types.FEED_UPDATED:
            return {
                ...state,
                select: action.payload,
            };
        case types.UPDATE_MODULES_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
        case types.SHOW_NOTIFICATION:
            return {
                ...state,
                justSaved: true,
                error: '',
            };
        case types.HIDE_NOTIFICATION:
            return {
                ...state,
                justSaved: false,
                error: '',
            };
        default:
            return state;
    }
}
