import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './fontawesome/fontawesome';
import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundary from './components/ErrorBoundary';
import { createBrowserHistory } from 'history';
import { setToken } from './token/setToken';
import configureStore from './stores/configureStore';


require('typeface-open-sans');

const App = lazy(() => import('./components/App'));
const Landing = lazy(() => import('./components/Landing'));
const Redirector = lazy(() => import('./components/Redirector'));
const Header = (() => import('./containers/header/Header'));
const PromotionPageError = lazy(() => import('./components/Promotion'));

const history = createBrowserHistory();
const store = configureStore(history);

//If we add the environment variables at this level, they will refresh on every refresh of the page.
//If a user is using the app without refreshing, they have BOTH the old built JS and the old variables.
//After a simple refresh they have the new JS and new variables. Everything stays in sync.
//Using date.now forces the environment variables to load every time the page is refreshed
const env = document.createElement('script');
env.src = '/env.js?v=' + Date.now();
env.async = true;
document.head.appendChild(env);
//setToken is necessary to be in this top level file or else App.js needs a fully new wrapper
// component just to initialize the function early enough in the lifecycle. It is such a tiny function that it's less overhead
// just to leave it here even for the landing page.
setToken();

render(<ErrorBoundary><Provider store={store}>
    <ConnectedRouter history={history}>
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route path="/landing/:orgId" component={Landing}/>
                <Route path="/vimeo-authentication" component={Redirector}/>
                <Route path="/" component={App}/>
                <Route path="/app-builder" component={Header} />
            </Switch>
        </Suspense>
    </ConnectedRouter>
</Provider></ErrorBoundary>, document.getElementById('app'));
