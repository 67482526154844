import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                data: action.payload.data,
            };
        default:
            return state;
    }
}
