import * as types from '../actions/actionTypes'; // eslint-disable-line

export default function WaffleReducer(state = {}, action) {
    switch (action.type) {
        case types.GET_WAFFLE_TOKEN_SUCCESS:
            return {
                data: action.payload.data,
            };
        default:
            return state;
    }
}
