import * as types from '../actions/actionTypes'; // eslint-disable-line

export default function messageReducer(state = { inProgress: false }, action) {
    switch (action.type) {
        case types.GET_MESSAGE_SUCCESS:
            return {
                ...state,
                select: action.payload,
                inProgress: false,
            };
        case types.GET_MESSAGE_PENDING:
            return {
                ...state,
                inProgress: true,
                select: null,
            };
        case types.GET_MESSAGE_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
        case types.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                inProgress: false,
                data: action.payload,
            };
        case types.GET_MESSAGES_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
        case types.GET_MESSAGES_TOTAL:
            return {
                ...state,
                total: action.payload,
            };
        case types.GET_MESSAGES_PENDING:
            return {
                ...state,
                inProgress: true,
                data: null,
            };
        case types.CREATE_MESSAGE_SUCCESS:
            return {
                ...state,
                select: action.payload,
                inProgress: false,
            };
        case types.CREATE_MESSAGE_PENDING:
            return {
                ...state,
                inProgress: true,
                select: null,
            };
        case types.CREATE_MESSAGE_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
        case types.UPDATE_MESSAGE_SUCCESS:
            return {
                ...state,
                select: action.payload,
                inProgress: false,
            };
        case types.UPDATE_MESSAGE_PENDING:
            return {
                ...state,
                inProgress: true,
                select: null,
            };
        case types.UPDATE_MESSAGE_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
        case types.DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                select: action.payload,
                inProgress: false,
            };
        case types.DELETE_MESSAGE_PENDING:
            return {
                ...state,
                inProgress: true,
                select: null,
            };
        case types.DELETE_MESSAGE_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
        default:
            return state;
    }
}
