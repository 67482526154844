export const LOG_OUT = 'LOG_OUT';

export const CHANGE_PROJECTS = 'CHANGE_PROJECTS';

export const LOGIN = 'logs_in_the_user';

export const GET_CALENDARS_PENDING = 'GET_CALENDARS_PENDING';
export const GET_CALENDARS_SUCCESS = 'GET_CALENDARS_SUCCESS';
export const GET_CALENDARS_FAILED = 'GET_CALENDARS_FAILED';
export const CALENDAR_FEED_INVALID = 'CALENDAR_FEED_INVALID';
export const FEED_UPDATED = 'CALENDAR_FEED_UPDATED';
export const GET_CLOVER_SELECTED_PRODUCT_SUCCESS = 'GET_CLOVER_SELECTED_PRODUCT_SUCCESS';
export const GET_CLOVER_PRODUCTS_PENDING = 'GET_CLOVER_PRODUCTS_PENDING';
export const GET_CLOVER_PRODUCTS_SUCCESS = 'GET_CLOVER_PRODUCTS_SUCCESS';
export const GET_CLOVER_PRODUCTS_FAILED = 'GET_CLOVER_PRODUCTS_FAILED';
export const GET_CLOVER_PAGES_FAILURE = 'GET_CLOVER_PAGES_FAILURE';
export const GET_CLOVER_PAGES_SUCCEED = 'GET_CLOVER_PAGES_SUCCEED';
export const GET_CLOVER_PAGES = 'GET_CLOVER_PAGES';
export const CLEAR_CLOVER_OBJECTS = 'CLEAR_CLOVER_OBJECTS';

export const GET_VIMEO_FEEDS_LIST_SUCCESS = 'GET_VIMEO_FEEDS_LIST_SUCCESS';
export const GET_VIMEO_FEEDS_LIST_FAILED = 'GET_VIMEO_FEEDS_LIST_FAILED';
export const GET_VIMEO_FEED_TOKEN = 'GET_VIMEO_FEED_TOKEN';

// ONLY FOR CROP
export const RESET_WARNING = 'RESET_WARNING';

export const UPLOAD_ASSET_IMAGE_CROP_SUCCESS = 'UPLOAD_ASSET_IMAGE_CROP_SUCCESS';
export const UPLOAD_ASSET_IMAGE_CROP_FAILED = 'UPLOAD_ASSET_IMAGE_CROP_FAILED';
export const UPLOAD_ASSET_IMAGE_CROP_PENDING = 'UPLOAD_ASSET_IMAGE_CROP_PENDING';

export const CROP_IMAGE_CROP_SUCCESS = 'CROP_IMAGE_CROP_SUCCESS';
export const CROP_IMAGE_CROP_PENDING = 'CROP_IMAGE_CROP_PENDING';

export const CREATE_IMAGE_CROP_SUCCESS = 'CREATE_IMAGE_CROP_SUCCESS';
export const CREATE_IMAGE_CROP_FAILED = 'CREATE_IMAGE_CROP_FAILED';

export const GET_CROPPED_IMAGE_CROP_SUCCESS = 'GET_CROPPED_IMAGE_CROP_SUCCESS';
export const GET_CROPPED_IMAGE_CROP_PENDING = 'GET_CROPPED_IMAGE_CROP_PENDING';
export const GET_CROPPED_IMAGE_CROP_FAILED = 'GET_CROPPED_IMAGE_CROP_FAILED';

export const GET_ORIGINAL_IMAGE_CROP_SUCCESS = 'GET_ORIGINAL_IMAGE_CROP_SUCCESS';
export const GET_ORIGINAL_IMAGE_CROP_PENDING = 'GET_ORIGINAL_IMAGE_CROP_PENDING';
export const GET_ORIGINAL_IMAGE_CROP_FAILED = 'GET_ORIGINAL_IMAGE_CROP_FAILED';

export const DELETE_ALL_CROPPED_IMAGES_CROP_SUCCESS = 'DELETE_ALL_CROPPED_IMAGES_CROP_SUCCESS';
export const DELETE_ALL_CROPPED_IMAGES_CROP_PENDING = 'DELETE_ALL_CROPPED_IMAGES_CROP_PENDING';
export const DELETE_ALL_CROPPED_IMAGES_CROP_FAILED = 'DELETE_ALL_CROPPED_IMAGES_CROP_FAILED';

export const DELETE_SINGLE_IMAGE_CROP_SUCCESS = 'DELETE_SINGLE_IMAGE_CROP_SUCCESS';
export const DELETE_SINGLE_IMAGE_CROP_PENDING = 'DELETE_SINGLE_IMAGE_CROP_PENDING';
export const DELETE_SINGLE_IMAGE_CROP_FAILED = 'DELETE_SINGLE_IMAGE_CROP_FAILED';

// Images

export const RESET_CROPPED_IMAGE = 'RESET_CROPPED_IMAGE';
export const RESET_IMAGE_DATA = 'RESET_IMAGE_DATA';

export const GET_ORIGINAL_IMAGE_FAILED = 'GET_ORIGINAL_IMAGE_FAILED';
export const CLOSE_CROPPER = 'CLOSE_CROPPER';

// Media
export const GET_PROJECT_MEDIA_PENDING = 'GET_PROJECT_MEDIA_PENDING';
export const GET_PROJECT_MEDIA_SUCCESS = 'GET_PROJECT_MEDIA_SUCCESS';
export const GET_PROJECT_MEDIA_FAILED = 'GET_PROJECT_MEDIA_FAILED';

// Modules
export const GET_PROJECT_VERSION_MODULES_SUCCESS = 'GET_PROJECT_VERSION_MODULES_SUCCESS';
export const GET_PROJECT_VERSION_MODULES_PENDING = 'GET_PROJECT_VERSION_MODULES_PENDING';
export const GET_PROJECT_VERSION_MODULES_FAILED = 'GET_PROJECT_VERSION_MODULES_FAILED';

export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS';
export const GET_MODULE_PENDING = 'GET_MODULE_PENDING';
export const GET_MODULE_FAILED = 'GET_MODULE_FAILED';

export const GET_WAFFLE_TOKEN_SUCCESS = 'GET_WAFFLE_TOKEN_SUCCESS';
export const GET_WAFFLE_TOKEN_PENDING = 'GET_WAFFLE_TOKEN_PENDING';
export const GET_WAFFLE_TOKEN_FAILED = 'GET_WAFFLE_TOKEN_FAILED';

export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS';
export const UPDATE_MODULE_PENDING = 'UPDATE_MODULE_PENDING';
export const UPDATE_MODULE_FAILED = 'UPDATE_MODULE_FAILED';

export const UPDATE_MODULES_SUCCESS = 'UPDATE_MODULES_SUCCESS';
export const UPDATE_MODULES_PENDING = 'UPDATE_MODULES_PENDING';
export const UPDATE_MODULES_FAILED = 'UPDATE_MODULES_FAILED';

export const UPDATE_MODULE_PARAMETER_SUCCESS = 'UPDATE_MODULE_PARAMETER_SUCCESS';
export const UPDATE_MODULE_PARAMETER_PENDING = 'UPDATE_MODULE_PARAMETER_PENDING';
export const UPDATE_MODULE_PARAMETER_FAILED = 'UPDATE_MODULE_PARAMETER_FAILED';

export const GET_MODULES_FOR_MENU = 'GET_MODULES_FOR_MENU';

export const UPDATE_MENUITEMS_SUCCESS = 'UPDATE_MENUITEMS_SUCCESS';
export const UPDATE_MENUITEMS_PENDING = 'UPDATE_MENUITEMS_PENDING';
export const UPDATE_MENUITEMS_FAILED = 'UPDATE_MENUITEMS_FAILED';

export const UPDATE_MENUITEM_PARAMETER_SUCCESS = 'UPDATE_MENUITEM_PARAMETER_SUCCESS';
export const UPDATE_MENUITEM_PARAMETER_PENDING = 'UPDATE_MENUITEM_PARAMETER_PENDING';
export const UPDATE_MENUITEM_PARAMETER_FAILED = 'UPDATE_MENUITEM_PARAMETER_FAILED';

export const GET_PROJECT_VERSION_MENUITEMS_SUCCESS = 'GET_PROJECT_VERSION_MENUITEMS_SUCCESS';
export const GET_PROJECT_VERSION_MENUITEMS_PENDING = 'GET_PROJECT_VERSION_MENUITEMS_PENDING';
export const GET_PROJECT_VERSION_MENUITEMS_FAILED = 'GET_PROJECT_VERSION_MENUITEMS_FAILED';

// Profile
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_YAY';
export const GET_PROFILE_PENDING = 'GET_PROFILE_PENDING';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const UPDATE_PROFILE_CHURCH_NAME_SUCCESS = 'UPDATE_PROFILE_CHURCH_NAME_SUCCESS';
export const UPDATE_PROFILE_CHURCH_NAME_PENDING = 'UPDATE_PROFILE_CHURCH_NAME_PENDING';
export const UPDATE_PROFILE_CHURCH_NAME_FAILED = 'UPDATE_PROFILE_CHURCH_NAME_FAILED';

export const UPDATE_PROFILE_CHURCH_ADDRESS_SUCCESS = 'UPDATE_PROFILE_CHURCH_ADDRESS_SUCCESS';
export const UPDATE_PROFILE_CHURCH_ADDRESS_PENDING = 'UPDATE_PROFILE_CHURCH_ADDRESS_PENDING';
export const UPDATE_PROFILE_CHURCH_ADDRESS_FAILED = 'UPDATE_PROFILE_CHURCH_ADDRESS_FAILED';

// Session
export const GET_SESSION_PENDING = 'GET_PROFILE_YAY';
export const GET_SESSION_FAILED = 'GET_PROFILE_PENDING';
export const GET_SESSION_SUCCESS= 'GET_PROFILE_FAILED';

// Project configuration
export const GET_PROJECT_CONFIG = 'GET_PROJECT_CONFIG';

export const CHECK_PROJECT_CONFIG_PENDING = 'CHECK_PROJECT_CONFIG_PENDING';
export const CHECK_PROJECT_CONFIG_SUCCESS = 'CHECK_PROJECT_CONFIG_SUCCESS';
export const CHECK_PROJECT_CONFIG_FAILED = 'CHECK_PROJECT_CONFIG_FAILED';

export const CHECK_PROJECT_VERSION_PENDING = 'CHECK_PROJECT_VERSION_PENDING';
export const CHECK_PROJECT_VERSION_SUCCESS = 'CHECK_PROJECT_VERSION_SUCCESS';
export const CHECK_PROJECT_VERSION_FAILED = 'CHECK_PROJECT_VERSION_FAILED';

// Project
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_PENDING = 'GET_PROJECTS_PENDING';
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILED = 'GET_PROJECT_FAILED';
export const GET_PROJECT_PENDING = 'GET_PROJECT_PENDING';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_PENDING = 'CREATE_PROJECT_PENDING';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAIL';
export const GET_LIVE_CONFIG = 'GET_LIVE_CONFIG';

// Project Version

export const GET_PROJECT_VERSION_SUCCESS = 'GET_PROJECT_VERSION_SUCCESS';
export const GET_PROJECT_VERSION_FAILED = 'GET_PROJECT_VERSION_FAILED';
export const GET_PROJECT_VERSION_PENDING = 'GET_PROJECT_VERSION_PENDING';

export const CREATE_PROJECT_VERSION_SUCCESS = 'CREATE_PROJECT_VERSION_SUCCESS';
export const CREATE_PROJECT_VERSION_FAILED = 'CREATE_PROJECT_VERSION_FAILED';
export const CREATE_PROJECT_VERSION_PENDING = 'CREATE_PROJECT_VERSION_PENDING';

export const UPDATE_PROJECT_VERSION_SUCCESS = 'UPDATE_PROJECT_VERSION_SUCCESS';
export const UPDATE_PROJECT_VERSION_PENDING = 'UPDATE_PROJECT_VERSION_PENDING';
export const UPDATE_PROJECT_VERSION_FAILED = 'UPDATE_PROJECT_VERSION_FAILED';

export const UPDATE_PROJECT_VERSION_PARAMETER_SUCCESS = 'UPDATE_PROJECT_VERSION_PARAMETER_SUCCESS';
export const UPDATE_PROJECT_VERSION_PARAMETER_PENDING = 'UPDATE_PROJECT_VERSION_PARAMETER_PENDING';
export const UPDATE_PROJECT_VERSION_PARAMETER_FAILED = 'UPDATE_PROJECT_VERSION_PARAMETER_FAILED';

export const DELETE_PROJECT_VERSION_SUCCESS = 'DELETE_PROJECT_VERSION_SUCCESS';
export const DELETE_PROJECT_VERSION_FAILED = 'DELETE_PROJECT_VERSION_FAILED';
export const DELETE_PROJECT_VERSION_PENDING = 'DELETE_PROJECT_VERSION_PENDING';

export const PUBLISH_PROJECT_PENDING = 'PUBLISH_PROJECT_PENDING';
export const PUBLISH_PROJECT_FAILED = 'PUBLISH_PROJECT_FAILED';
export const PUBLISH_PROJECT_SUCCESS = 'PUBLISH_PROJECT_SUCCESS';
export const CANCEL_SUBMISSION_SUCCESS = 'CANCEL_SUBMISSION_SUCCESS';
export const CANCEL_SUBMISSION_PENDING = 'CANCEL_SUBMISSION_PENDING';
export const CANCEL_SUBMISSION_FAILED = 'CANCEL_SUBMISSION_FAILED';

export const UPDATE_STATUS = 'UPDATE_STATUS';

export const DISCONNECT_MEDIA_PENDING = 'DISCONNECT_MEDIA_PENDING';
export const DISCONNECT_MEDIA_SUCCESS = 'DISCONNECT_MEDIA_SUCCESS';
export const DISCONNECT_MEDIA_FAILED = 'DISCONNECT_MEDIA_FAILED';

// Templates
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_PENDING = 'GET_TEMPLATES_PENDING';
export const GET_TEMPLATES_FAILED = 'GET_TEMPLATES_FAILED';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_PENDING = 'GET_TEMPLATE_PENDING';
export const GET_TEMPLATE_FAILED = 'GET_TEMPLATE_FAILED';

export const GET_FORMS_SUCCESS = 'GET_FORMS_SUCCESS';
export const GET_FORMS_PENDING = 'GET_FORMS_PENDING';
export const GET_FORMS_FAILED = 'GET_FORMS_FAILED';
export const GET_FORMS_CATEGORIES = 'GET_FORMS_CATEGORIES';

export const FIRE_ALERT = 'FIRE_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const SET_MENU_OPEN_SECTION = 'SET_MENU_OPEN_SECTION';
export const TOGGLE_MENU_SHOWN = 'TOGGLE_MENU_SHOWN';
export const TOGGLE_MOBILE_MENU_SHOWN = 'TOGGLE_MOBILE_MENU_SHOWN';
export const TOGGLE_PREVIEW_SHOWN = 'TOGGLE_PREVIEW_SHOWN';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const GET_EMBED_CODE_SUCCESS = 'GET_EMBED_CODE_SUCCESS';
export const GET_EMBED_CODE_PENDING = 'GET_EMBED_CODE_PENDING';
export const GET_EMBED_CODE_FAILED = 'GET_EMBED_CODE_FAILED';

export const SHOW_PUBLISH_NOTIFICATION = 'SHOW_PUBLISH_NOTIFICATION';
export const HIDE_PUBLISH_NOTIFICATION = 'HIDE_PUBLISH_NOTIFICATION';

export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_PENDING = 'GET_MESSAGE_PENDING';
export const GET_MESSAGE_FAILED = 'GET_MESSAGE_FAILED';

export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_PENDING = 'GET_MESSAGES_PENDING';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';
export const GET_MESSAGES_TOTAL = 'GET_MESSAGES_TOTAL';

export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_PENDING = 'UPDATE_MESSAGE_PENDING';
export const UPDATE_MESSAGE_FAILED = 'UPDATE_MESSAGE_FAILED';

export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_PENDING = 'CREATE_MESSAGE_PENDING';
export const CREATE_MESSAGE_FAILED = 'CREATE_MESSAGE_FAILED';

export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_PENDING = 'DELETE_MESSAGE_PENDING';
export const DELETE_MESSAGE_FAILED = 'DELETE_MESSAGE_FAILED';

export const SEARCH_STREAMS_SUCCESS = 'SEARCH_STREAMS_SUCCESS';
export const SEARCH_STREAMS_PENDING = 'SEARCH_STREAMS_PENDING';
export const SEARCH_STREAMS_FAILED = 'SEARCH_STREAMS_FAILED';

export const CHECK_SF_EMAIL_SUCCESS = 'CHECK_SF_EMAIL_SUCCESS';
export const CHECK_SF_EMAIL_PENDING = 'CHECK_SF_EMAIL_PENDING';
export const CHECK_SF_EMAIL_FAILED = 'CHECK_SF_EMAIL_FAILED';

export const GET_FLAGS_SUCCESS = 'GET_FLAGS_SUCCESS';
export const GET_FLAGS_PENDING = 'GET_FLAGS_PENDING';
export const GET_FLAGS_FAILED = 'GET_FLAGS_FAILED';

//Custom Content
export const GET_PROJECT_PAGES_SUCCESS = 'GET_PROJECT_PAGES_SUCCESS';
export const GET_PROJECT_PAGES_PENDING = 'GET_PROJECT_PAGES_PENDING';
export const GET_PROJECT_PAGES_FAILED = 'GET_PROJECT_PAGES_FAILED';

export const GET_PROJECT_PAGES_DROPDOWN_SUCCESS = 'GET_PROJECT_PAGES_DROPDOWN_SUCCESS';
export const GET_PROJECT_PAGES_DROPDOWN_PENDING = 'GET_PROJECT_PAGES_DROPDOWN_PENDING';
export const GET_PROJECT_PAGES_DROPDOWN_FAILED = 'GET_PROJECT_PAGES_DROPDOWN_FAILED';

export const ADD_CONTENT_SECTION_SUCCESS = 'ADD_CONTENT_SECTION_SUCCESS';
export const ADD_CONTENT_SECTION_PENDING = 'ADD_CONTENT_SECTION_PENDING';
export const ADD_CONTENT_SECTION_FAILED = 'ADD_CONTENT_SECTION_FAILED';

export const REORDER_SECTIONS_SUCCESS = 'REORDER_SECTIONS_SUCCESS';
export const REORDER_SECTIONS_PENDING = 'REORDER_SECTIONS_PENDING';
export const REORDER_SECTIONS_FAILED = 'REORDER_SECTIONS_FAILED';


export const DELETE_CONTENT_SECTION_SUCCESS = 'DELETE_CONTENT_SECTION_SUCCESS';
export const DELETE_CONTENT_SECTION_PENDING = 'DELETE_CONTENT_SECTION_PENDING';
export const DELETE_CONTENT_SECTION_FAILED = 'DELETE_CONTENT_SECTION_FAILED';

export const UPDATE_CONTENT_SECTION_SUCCESS = 'UPDATE_CONTENT_SECTION_SUCCESS';
export const UPDATE_CONTENT_SECTION_PENDING = 'UPDATE_CONTENT_SECTION_PENDING';
export const UPDATE_CONTENT_SECTION_FAILED = 'UPDATE_CONTENT_SECTION_FAILED';

export const ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS';
export const ADD_CONTENT_PENDING = 'ADD_CONTENT_PENDING';
export const ADD_CONTENT_FAILED = 'ADD_CONTENT_FAILED';

export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_PENDING = 'DELETE_CONTENT_PENDING';
export const DELETE_CONTENT_FAILED = 'DELETE_CONTENT_FAILED';

export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_PENDING = 'UPDATE_CONTENT_PENDING';
export const UPDATE_CONTENT_FAILED = 'UPDATE_CONTENT_FAILED';

export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_PENDING = 'DELETE_PAGE_PENDING';
export const DELETE_PAGE_FAILED = 'DELETE_PAGE_FAILED';

export const ADD_PAGE_SUCCESS = 'ADD_PAGE_SUCCESS';
export const ADD_PAGE_PENDING = 'ADD_PAGE_PENDING';
export const ADD_PAGE_FAILED = 'ADD_PAGE_FAILED';

export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const UPDATE_PAGE_PENDING = 'UPDATE_PAGE_PENDING';
export const UPDATE_PAGE_FAILED = 'UPDATE_PAGE_FAILED';
