import { compose, createStore, applyMiddleware } from 'redux'; // eslint-disable-line
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

export default function configureStore(history) {
    const routerReduxMiddleware = routerMiddleware(history);

    let middleware = applyMiddleware(
        thunk,
        loadingBarMiddleware({
            promiseTypeSuffixes: ['PENDING', 'SUCCESS', 'FAILED'],
        }),
        routerReduxMiddleware,
    );
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined) {
        middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer(history),
        {},
        middleware,
    );
}
