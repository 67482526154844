export default { // eslint-disable-line
    error: '',
    data: [],
    select: {},
    latestAsset: '',
    inProgress: false,
    cropimageLink: '',
    wrongResolution: false,
    hasCroppedImage: false,
};
