import * as types from '../actions/actionTypes';
import initialState from './initialState';

const clearAlertsForState = (state, location) => {
    if (state && state.data) {
        return state.data.filter((a) => {
            return a.global || location.pathname.indexOf(a.view) > 0;
        });
    }
    return [];
};

export default function alertReducer(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_IMAGE_CROP_SUCCESS:
            const crop = clearAlertsForState(state.data, action.location); // eslint-disable-line
            return {
                ...state,
                data: crop,
            };
        case types.CLEAR_ALERT: {
            const alerts = state.data.filter((a) => {
                return a.id !== action.payload;
            });
            const postpoppedState = [
                ...alerts,
            ];
            return {
                ...state,
                data: postpoppedState,
            };
        }
        case types.FIRE_ALERT: {
            if (!state.data.some((alert) => { return alert.id === action.payload.id; })) {
                const postpushedState = [
                    ...state.data,
                    action.payload,
                ];
                return {
                    ...state,
                    data: postpushedState,
                };
            }
            return {
                ...state,
            };
        }
        default:
            return state;
    }
}
