import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function projectReducer(state = {
    ...initialState,
    token: '',
}, action) {
    switch (action.type) {
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                token: action.token,
                inProgress: false,
            };
        case types.GET_PROFILE_PENDING:
            return {
                ...state,
                inProgress: true,
                error: '',
            };
        case types.GET_PROFILE_FAILED:
            return {
                error: action.error,
                inProgress: false,
            };

        case types.UPDATE_PROFILE_CHURCH_NAME_SUCCESS:
            return {
                ...state,
                data: action.payload,
                token: action.token,
                inProgress: false,
            };
        case types.UPDATE_PROFILE_CHURCH_NAME_PENDING:
            return {
                ...state,
                inProgress: true,
                error: '',
            };
        case types.UPDATE_PROFILE_CHURCH_NAME_FAILED:
            return {
                ...state,
                error: action.error,
                inProgress: false,
            };
        case types.UPDATE_PROFILE_CHURCH_ADDRESS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                token: action.token,
                inProgress: false,
            };
        case types.UPDATE_PROFILE_CHURCH_ADDRESS_PENDING:
            return {
                ...state,
                inProgress: true,
                error: '',
            };
        case types.UPDATE_PROFILE_CHURCH_ADDRESS_FAILED:
            return {
                ...state,
                error: action.error,
                inProgress: false,
            };
        default:
            return state;
    }
}
