import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialCropState from './initialCropState';

export default function cropReducer(state = {
    ...initialCropState,
    successMessage: '',
}, action) {
    switch (action.type) {
        // RESET WARNING
        case types.RESET_WARNING:
            return {
                ...state,
                wrongResolution: false,
            };

        case types.RESET_IMAGE_DATA:
            return { ...initialCropState };

        // RESET CROPPED IMAGE
        case types.RESET_CROPPED_IMAGE:
            if (state.originalImage) {
                return {
                    ...state,
                    croppedImage: '',
                    modal: true,
                    hasCroppedImage: false,
                    wrongResolution: false,
                };
            }
            return {
                ...state,
                croppedImage: '',
                hasCroppedImage: false,
                wrongResolution: false,
            };

        // SAVING CROPPED IMAGE TO DB/AZURE
        case types.CROP_IMAGE_CROP_SUCCESS:
            return {
                ...state,
                croppedImage: { ...state.croppedImage, imagePath: action.payload.imagePath },
                modal: false,
                hasCroppedImage: true,
                isRecrop: false,
                inProgress: false,
            };
        case types.CROP_IMAGE_CROP_PENDING:
            return {
                ...state,
                inProgress: true,
                hasCroppedImage: false,
                error: '',
                wrongResolution: false,
            };

        // SAVE IMAGE TO DB/AZURE
        case types.UPLOAD_ASSET_IMAGE_CROP_SUCCESS:
            if (action.payload.isOriginal === true) {
                return {
                    ...state,
                    originalImage: action.payload,
                    modal: true,
                    isRecrop: action.crop,
                    wrongResolution: false,
                };
            }

            return {
                ...state,
                croppedImage: action.payload,
                modal: true,
                isRecrop: action.crop,
                wrongResolution: false,
            };

        case types.UPLOAD_ASSET_IMAGE_CROP_PENDING:
            return {
                ...state,
                inProgress: true,
                wrongResolution: false,
            };
        case types.UPLOAD_ASSET_IMAGE_CROP_FAILED:
            return {
                ...state,
                error: action.error,
                inProgress: false,
                modal: false,
                isRecrop: action.crop,
                wrongResolution: true,
            };
        default:
            return state;

        // RETRIEVE CROPPED VERSION, USED FOR LIVE PREVIEWS
        case types.GET_CROPPED_IMAGE_CROP_SUCCESS:
            return {
                ...state,
                croppedImage: action.payload,
                hasCroppedImage: true,
            };
        case types.GET_CROPPED_IMAGE_CROP_PENDING:
            return {
                ...state,
                inProgress: true,
            };
        case types.GET_CROPPED_IMAGE_CROP_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
                hasCroppedImage: false,
            };

        // GET ORIGINAL IMAGE TO USE FOR A RECROP
        case types.GET_ORIGINAL_IMAGE_CROP_SUCCESS:
            return {
                ...state,
                originalImage: action.payload,
                croppedImage: null,
                modal: true,
                isRecrop: true,
            };
        case types.GET_ORIGINAL_IMAGE_CROP_PENDING:
            return {
                ...state,
                inProgress: true,
            };
        case types.GET_ORIGINAL_IMAGE_CROP_FAILED:
            return {
                ...state,
                originalImage: {},
                inProgress: false,
                error: action.error,
                modal: false,
            };

        // CLOSE THE CROPPER
        case types.CLOSE_CROPPER:
            return {
                ...state,
                inProgress: false,
                modal: false,
            };

        // DELETE ALL IMAGES: CROPPED OR/AND ORIGINAL
        case types.DELETE_ALL_CROPPED_IMAGES_CROP_SUCCESS:
            return {
                ...state,
                latestAssetId: action.payload,
                hasCroppedImage: false,
                croppedImage: null,
                modal: false,
            };
        case types.DELETE_ALL_CROPPED_IMAGES_CROP_PENDING:
            return {
                ...state,
                inProgress: true,
                deletingImages: true,
            };
        case types.DELETE_ALL_CROPPED_IMAGES_CROP_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
                hasCroppedImage: true,
            };

        // CREATE NEW ORIGINAL IMAGE FOR CROPPING
        case types.CREATE_IMAGE_CROP_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };

        // DELETE SINGLE IMAGE WHEN CANCELLED BEFORE SAVING CROP
        case types.DELETE_SINGLE_IMAGE_CROP_SUCCESS:
            return {
                ...state,
                isRecrop: false,
                modal: false,
            };
        case types.DELETE_SINGLE_IMAGE_CROP_PENDING:
            return {
                ...state,
                inProgress: true,
            };
        case types.DELETE_SINGLE_IMAGE_CROP_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.error,
            };
    }
}
