import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function projectConfigReducer(state = {
    ...initialState,
    submission_version: {},
}, action) {
    switch (action.type) {
        case types.CHECK_PROJECT_CONFIG_PENDING:
            return {
                ...state,
                inProgress: true,
                publishFailed: false,
                scanFailed: false,
                projectScanned: false,
            };
        case types.CHECK_PROJECT_CONFIG_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inProgress: false,
                projectScanned: true,
            };
        case types.CHECK_PROJECT_CONFIG_FAILED:
            return {
                ...state,
                data: action.payload,
                scanFailed: true,
                inProgress: false,
                projectScanned: true,
            };
        case types.PUBLISH_PROJECT_FAILED:
            return {
                ...state,
                publishFailed: true,
            };

        case types.CHECK_PROJECT_VERSION_PENDING:
            return {
                ...state,
                inProgress: true,
                error: '',
            };
        case types.CHECK_PROJECT_VERSION_SUCCESS:
            return {
                ...state,
                inProgress: false,
                submission_version: action.payload,
            };
        case types.CHECK_PROJECT_VERSION_FAILED:
            return {
                ...state,
                inProgress: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
