import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function projectReducer(state = {
    ...initialState,
    data: [],
}, action) {
    switch (action.type) {
        case types.GET_PROJECT_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                selected: action.payload,
            };
        case types.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                data: action.payload,
                inProgress: false,
            };
        case types.GET_PROJECTS_PENDING:
            return {
                ...state,
                inProgress: true,
                error: '',
                newIds: null,
            };
        case types.GET_PROJECTS_FAILED:
            return {
                ...state,
                error: action.error,
                inProgress: false,
            };

        case types.DELETE_PROJECT_VERSION_SUCCESS:
            return {
                ...state,
                deleted: true,
            };

        case types.DELETE_PROJECT_VERSION_FAILED:
            return {
                ...state,
                deleted: false,
            };

        case types.UPDATE_PROJECT_VERSION_PARAMETER_SUCCESS:
            return {
                ...state,
                updatedNew: true,
            };

        case types.SHOW_NOTIFICATION:
            return {
                ...state,
                justSaved: true,
                error: '',
            };
        case types.HIDE_NOTIFICATION:
            return {
                ...state,
                justSaved: false,
                error: '',
            };

        case types.SHOW_PUBLISH_NOTIFICATION:
            return {
                ...state,
                justPublished: true,
                error: '',
            };
        case types.HIDE_PUBLISH_NOTIFICATION:
            return {
                ...state,
                justPublished: false,
                error: '',
            };
        case types.CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                newIds: action.payload.ids,
                inProgress: false,
            };
        case types.CREATE_PROJECT_PENDING:
            return {
                ...state,
                inProgress: true,
                error: '',
            };
        case types.CREATE_PROJECT_FAILED:
            return {
                ...state,
                error: action.error,
                inProgress: false,
            };
        case types.GET_LIVE_CONFIG:
            return {
                ...state,
                liveConfig: action.payload,
            };
        default:
            return state;
    }
}
