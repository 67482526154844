import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function PagesReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PROJECT_PAGES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                inProgress: false,
            };
        case types.GET_PROJECT_PAGES_PENDING:
            return {
                ...state,
                inProgress: true,
            };
        case types.GET_PROJECT_PAGES_FAILED:
            return {
                ...state,
                error: action.error,
                data: [],
                inProgress: false,
            };
        case types.GET_PROJECT_PAGES_DROPDOWN_SUCCESS:
            return {
                ...state,
                dropdown: action.payload.data,
                dropInProgress: false,
            };
        case types.GET_PROJECT_PAGES_DROPDOWN_PENDING:
            return {
                ...state,
                dropInProgress: true,
            };
        case types.GET_PROJECT_PAGES_DROPDOWN_FAILED:
            return {
                ...state,
                error: action.error,
                dropdown: [],
                dropInProgress: false,
            };

        default:
            return state;
    }
}
