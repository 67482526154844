import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function ContentReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_CONTENT_SUCCESS:
            return {
                ...state,
                inProgress: false,
            };
        case types.ADD_CONTENT_PENDING:
            return {
                ...state,
                inProgress: true,
            };
        case types.ADD_CONTENT_FAILED:
            return {
                ...state,
                error: action.error,
                inProgress: false,
            };

        default:
            return state;
    }
}
