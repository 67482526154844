export const setToken = () => { // eslint-disable-line
    if (typeof (Storage) !== 'undefined' && localStorage.getItem('AuthToken')) {
        window.genesisToken = () => {
            return localStorage.getItem('AuthToken');
        };
    } else {
        window.genesisToken = () => {
            return '';
        };
    }
};

export const setsessionId = () => { // eslint-disable-line
    if (typeof (Storage) !== 'undefined' && localStorage.getItem('SessionId')) {
        window.unitySession = () => {
            return localStorage.getItem('SessionId');
        };
    } else {
        window.unitySession = () => {
            return '';
        };
    }
};

export default setToken;
