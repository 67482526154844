import * as types from '../actions/actionTypes'; // eslint-disable-line

export default function SidecarReducer(state = {
    openSection: '',
    show: true,
    showMobile: false,
}, action) {
    let newModules;
    switch (action.type) {
        case types.SET_MENU_OPEN_SECTION:
            return {
                ...state,
                openSection: action.payload,
            };
        case types.TOGGLE_MENU_SHOWN:
            return {
                ...state,
                show: action.payload,
            };
        case types.TOGGLE_MOBILE_MENU_SHOWN:
            return {
                ...state,
                showMobile: action.payload,
            };
        case types.GET_MODULES_FOR_MENU:
            return {
                ...state,
                modules: action.payload,
            };
        case types.UPDATE_MODULE_PARAMETER_SUCCESS:
            newModules = state.modules;
            if (action.moduleEnable) {
                if (action.payload?.enabled) {
                    if (state.modules.indexOf(action.payload?.moduleType) === -1) {
                        newModules.push(action.payload.moduleType);
                    }
                } else if (state.modules.indexOf(action.payload.moduleType > -1)) {
                    newModules = state.modules.filter((m) => {
                        return m !== action.payload.moduleType;
                    });
                }
            }
            return {
                ...state,
                modules: newModules,
            };
        default:
            return state;
    }
}
