import * as types from '../actions/actionTypes'; // eslint-disable-line

const init = {
    inProgress: false,
};

export default function FlagsReducer(initialState = init, action) {
    switch (action.type) {
        case types.GET_FLAGS_PENDING:
            return {
                inProgress: true,
            };
        case types.GET_FLAGS_SUCCESS:
            return {
                inProgress: false,
                // Adding in customContentPageCount to the results object becuase we don't have a more elegant way to do this.  
                // Ideally, a new endpoint could account for "custom" values.  This value is actually hardcoded to 250 in the API.
                results: { ...action.payload, customContentPageCount: 250 }
            };
        case types.GET_FLAGS_FAILED:
            return {
                inProgress: false,
                error: action.error,
            };
        default:
            return initialState;
    }
}
