import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let logger; 
let n = 0;
const waitForKey = () => {
    if (typeof window.APPINSIGHTS !== 'undefined') {
        const ai = new ApplicationInsights({
            config: {
                instrumentationKey: window.APPINSIGHTS ? window.APPINSIGHTS : '',
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory },
                },
            },
        });
        ai.loadAppInsights();
        logger = ai.appInsights;
        console.log('AI Connected');
    } else {
        n++;
        if (n < 10) {
            setTimeout(waitForKey, 50);
        } else {
            const ai = new ApplicationInsights({
                config: {
                    instrumentationKey: '',
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory },
                    },
                },
            });
            ai.loadAppInsights();
            logger = ai.appInsights;
            console.error('Application Insights Key Not Defined');
        }
    }
};

waitForKey();

export default (Component) => withAITracking(reactPlugin, Component);
export { logger };
