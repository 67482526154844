/* eslint-disable */
import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { connectRouter } from 'connected-react-router';
import AuthReducer from './auth-reducer';
import MediaReducer from './media-reducer';
import ModulesReducer from './moduleReducer';
import ProfileReducer from './profileReducer';
import ProjectConfigurationReducer from './proj-conf-reducer';
import ProjectVersionReducer from './proj-version-reducer';
import ProjectReducer from './project-reducer';
import TemplateReducer from './TemplateReducer';
import FMSReducer from './FMSReducer';
import AlertReducer from './AlertReducer';
import CropReducer from './CropReducer';
import CloverReducer from './CloverReducer';
import SidecarReducer from './SidecarReducer';
import PublishReducer from './PublishReducer';
import MessageReducer from './messageReducer';
import OrganizationReducer from './OrganizationReducer';
import IntegrationsReducer from './integrationreducer';
import FlagsReducer from './flagsreducer';
import MenuItemReducer from './menuItemReducer';
import VimeoReducer from './VimeoReducer';
import CalendarReducer from './calendarReducer';
import WaffleReducer from './WaffleReducer';
import PagesReducer from './pagesReducer';
import ContentReducer from './contentReducer';

const appReducer = (history) => combineReducers({
    auth: AuthReducer,
    calendar: CalendarReducer,
    crop: CropReducer,
    media: MediaReducer,
    modules: ModulesReducer,
    menuItems: MenuItemReducer,
    proj_conf: ProjectConfigurationReducer,
    projects: ProjectReducer,
    versions: ProjectVersionReducer,
    templates: TemplateReducer,
    profiles: ProfileReducer,
    forms: FMSReducer,
    alerts: AlertReducer,
    loadingBar: loadingBarReducer,
    router: connectRouter(history),
    clover: CloverReducer,
    sidecar: SidecarReducer,
    organization: OrganizationReducer,
    publish: PublishReducer,
    messages: MessageReducer,
    integrations: IntegrationsReducer,
    flags: FlagsReducer,
    vimeo: VimeoReducer,
    waffle: WaffleReducer,
    pages: PagesReducer,
    content: ContentReducer,
});

const rootReducer = (history) => (state, action) => {
    if (action.type === 'CHANGE_PROJECTS') {
        const { router, profiles, flags, alerts, waffle } = state;
        alerts.data = alerts.data.filter(alert => alert.id === 'apple' );
        state = {
            router,
            profiles,
            flags,
            alerts,
            waffle,
        };
    }
    if (action.type === 'LOG_OUT') {
        const { router } = state;
        state = { router };
    }
    return appReducer(history)(state, action);
};
export default rootReducer;
